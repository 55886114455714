.ol-map {
  margin: 50px;
}
.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}
